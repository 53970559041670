import React from 'react';

import { SiteDetailsTabProps } from '../types';
import TasksCluster from '../../../../../../components/clusters/TasksCluster/TasksCluster';

export const Tasks: React.FC<SiteDetailsTabProps> = ({ siteDetails, companyDetails }) => (
  <TasksCluster module="O&M" scope="site" companyId={companyDetails.id} siteId={siteDetails.id} />
);

export default Tasks;
