import React from 'react';
import { Outlet } from 'react-router-dom';

import AIAssistant from './components/AIAssistant';

export const DueDiligenceModuleContainer: React.FC = () => (
  <>
    <Outlet />
    <AIAssistant />
  </>
);

export default DueDiligenceModuleContainer;
