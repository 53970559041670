export type Crumb = {
  title: string;
  link?: string;
};
export type Crumbs = Crumb[];

type CrumbsBuilder = { (data?: unknown): Crumbs } | { (): Crumbs };

type ModuleId = 'asset-management' | 'operations-and-maintenance' | 'dashboard' | 'due-diligence';

type ModuleFeatures = 'ai-assistant';

interface RouteHandleParams {
  moduleId?: ModuleId;
  crumbsBuilder?: CrumbsBuilder;
  route?: string;
  enabledFeatures?: ModuleFeatures[];
}

export class RouteHandle {
  private static routesCrumbBuilderMapping: Readonly<{ [key: string]: CrumbsBuilder }> = Object.freeze({
    '/settings/company/add': () => [{ title: 'Settings', link: '/settings' }, { title: 'Add Company' }],
    '/settings/company/:companyId': () => [{ title: 'Settings', link: '/settings' }, { title: 'Edit Company' }],
    '/settings/company/:companyId/connections': () => [
      { title: 'Settings', link: '/settings' },
      { title: 'Manage Connections' }
    ],
    '/settings/users/add': () => [{ title: 'Settings', link: '/settings' }, { title: 'Add User' }],
    '/settings/users/{id}/edit': () => [{ title: 'Settings', link: '/settings' }, { title: 'Edit User' }],
    '/settings/company/:companyId/site/add': () => [{ title: 'Settings', link: '/settings' }, { title: 'Add Site' }],
    '/settings/company/:companyId/site/:siteId/edit': () => [
      { title: 'Settings', link: '/settings' },
      { title: 'Edit Site' }
    ],
    '/settings/my-company': () => [
      { title: 'Settings', link: '/settings' },
      { title: 'My Company', link: '/settings/my-company' },
      { title: 'Overview' }
    ],
    '/settings/my-company/edit': () => [
      { title: 'Settings', link: '/settings' },
      { title: 'My Company', link: '/settings/my-company' },
      { title: 'Edit Company' }
    ],
    '/settings/my-company/overview': () => [
      { title: 'Settings', link: '/settings' },
      { title: 'My Company', link: '/settings/my-company' },
      { title: 'Overview' }
    ],
    '/settings/my-company/sites': () => [
      { title: 'Settings', link: '/settings' },
      { title: 'My Company', link: '/settings/my-company' },
      { title: 'Sites' }
    ],
    '/settings/my-company/site/:siteId/edit': () => [
      { title: 'Settings', link: '/settings' },
      { title: 'My Company', link: '/settings/my-company' },
      { title: 'Edit Site' }
    ],
    '/settings/my-company/users': () => [
      { title: 'Settings', link: '/settings' },
      { title: 'My Company', link: '/settings/my-company' },
      { title: 'Users' }
    ],
    '/settings/my-company/users/add': () => [
      { title: 'Settings', link: '/settings' },
      { title: 'My Company', link: '/settings/my-company' },
      { title: 'Add User' }
    ],
    '/settings/my-company/users/:userId/edit': () => [
      { title: 'Settings', link: '/settings' },
      { title: 'My Company', link: '/settings/my-company' },
      { title: 'Edit User' }
    ]
  });
  private static routesModuleIdMapping: Readonly<{ [key: string]: string[] }> = Object.freeze({
    'asset-management': ['/asset-management'],
    'operations-and-maintenance': ['/operations-and-maintenance'],
    dashboard: ['/dashboard'],
    'due-diligence': ['/due-diligence']
  });

  private crumbsBuilder: CrumbsBuilder | null;
  private moduleId: string | null;
  private enabledFeatures: ModuleFeatures[];

  private constructor(
    moduleId: string | null,
    crumbsBuilder: CrumbsBuilder | null,
    enabledFeatures?: ModuleFeatures[]
  ) {
    this.moduleId = moduleId;
    this.crumbsBuilder = crumbsBuilder;
    this.enabledFeatures = enabledFeatures ?? [];
  }

  public buildCrumbs(data: unknown): Crumbs {
    const builder = this.crumbsBuilder;
    return builder ? builder(data) : [];
  }

  public getModuleId(): string | null {
    return this.moduleId;
  }

  public getEnabledFeaturesList(): ModuleFeatures[] {
    return [...this.enabledFeatures];
  }

  public static createHandle(params: RouteHandleParams) {
    const { moduleId, route, crumbsBuilder, enabledFeatures } = params;

    if (route) {
      const _crumbsBuilder = RouteHandle.routesCrumbBuilderMapping[route] || null;
      const _moduleId =
        Object.keys(RouteHandle.routesModuleIdMapping).find(key =>
          RouteHandle.routesModuleIdMapping[key].includes(route)
        ) || null;

      return new RouteHandle(_moduleId, _crumbsBuilder);
    }

    return new RouteHandle(moduleId ?? null, crumbsBuilder ?? null, enabledFeatures);
  }
}

export const routeHandlesMap: Readonly<{ [key: string]: RouteHandle }> = Object.freeze(
  [
    '/settings/company/add',
    '/settings/company/:companyId',
    '/settings/company/:companyId/connections',
    '/settings/company/:companyId/site/add',
    '/settings/company/:companyId/site/:siteId/edit',
    '/settings/users/add',
    '/settings/users/{id}/edit',
    '/asset-management',
    '/operations-and-maintenance',
    '/production-monitoring',
    '/settings/my-company',
    '/settings/my-company/edit',
    '/settings/my-company/overview',
    '/settings/my-company/sites',
    '/settings/my-company/site/:siteId/edit',
    '/settings/my-company/users',
    '/settings/my-company/users/add',
    '/settings/my-company/users/:userId/edit',
    'dashboard',
    'due-diligence'
  ].reduce((handles, route) => ({ ...handles, [route]: RouteHandle.createHandle({ route }) }), {})
);
