import React from 'react';

import { CompanyDetailsTabProps } from '../types';
import TasksCluster from '../../../../../../components/clusters/TasksCluster/TasksCluster';

export const Tasks: React.FC<CompanyDetailsTabProps> = ({ companyDetails }) => (
  <TasksCluster module="O&M" scope="company" companyId={companyDetails.id} />
);

export default Tasks;
